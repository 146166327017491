import React from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper";
import banner1 from "../assets/img/christmas-banner.jpg";
import banner2 from "../assets/img/banner-2.png";

import { Link } from "react-router-dom";

SwiperCore.use([Autoplay, Pagination, EffectFade]);
SwiperCore.use([Autoplay, Pagination, EffectFade]);

const MainSliderTwo = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    pagination: {
      el: "#main-slider-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
  };
  console.log(banner1, "banner");
  return (
    <section className="main-slider main-slider__two">
      <Swiper {...mainSlideOptions}>
        <SwiperSlide>
        <div className="image-layer">
      
      <img
        src={banner1} 
        alt="Banner 1"
        loading="lazy" 
        quality={100}
        className="image-layer-img" 
      />
    </div>

          <Container>
            <Row className="justify-content-start">
              <Col xl={6} lg={12} className="text-left">
                <h2>
                  Together We <br />
                  Make A Difference
                </h2>

                <div className="header-learn-more-btn mt-4">
                  <Link to="/give/samsahotra">
                    <Button className="scroll-to-target thm-btn dynamic-radius">
                      GIVE
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="image-layer"
            
            // style={{ backgroundImage: `url(${banner2})` }}
           
          > <img
          src={banner2} 
          alt="Banner 1"
          loading="lazy" 
          quality={100}
          className="image-layer-img" 
        /></div>

          <Container>
            <Row className=" justify-content-start">
              <Col xl={6} lg={12} className="text-left">
                <h2>
                  Together We <br />
                  Make A Difference
                </h2>

                <div className="header-learn-more-btn mt-4">
                  <Link to="/give/samsahotra">
                    <Button className="scroll-to-target thm-btn dynamic-radius">
                      GIVE{" "}
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default MainSliderTwo;
